<template>
    <el-dialog
            :title="title"
            :visible.sync="dialogVisible"
            :before-close="()=>onClose()"
            @open="onOpen"
            class="box_1">

        <div class="base-container give-mark">
            <div class="item" v-loading="loading">
                <el-carousel v-if="dataList.length>0" ref="carouselRef" :interval="5000"
                             :initial-index="initialIndex"
                             :autoplay="false"
                             :loop="false" arrow="never"
                             indicator-position="none">
                    <el-carousel-item v-for="(item, index) in dataList" :key="index">
                        <div class="giveMark flex">
                            <!-- left -->
                            <div class="mark_left">
                                <div class="imgBox img_big dialog">
                                    <el-image
                                            fit="contain"
                                            :src="$store.getters.getFilePath+item.images"
                                            :preview-src-list="[$store.getters.getFilePath+item.images]"
                                    >
                                    </el-image>
                                </div>
                                <!--<div class="work-content">
                                    <div>作品编号：{{item.number}}</div>
                                    <div>精度：{{item.precision}}</div>
                                </div>-->
                            </div>
                            <!-- right -->
                            <div class="mark_right">
                                <!-- 科目名称 -->
                                <div class="generalReview aa">
                                    <div class="flex">
                                        <div class="item-box">科目名称: {{$store.state.subjectObj.subject_name}}</div>
                                        <div class="item-box">作品总数: {{total}}</div>
                                    </div>
                                    <div class="reason-box">
                                        <div class="reason-tit">异常原因:</div>
                                        <div class="reason-txt">{{item.message}}</div>
                                    </div>
                                </div>

                                <div style="text-align: center;margin-top: 40px">
                                    <el-button type="primary" @click="setNormal(item.id_card, 2)">取消异常</el-button>
                                </div>
                            </div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
                <el-empty v-else class="empty-area" description="暂无数据"></el-empty>
            </div>
        </div>
    </el-dialog>
</template>

<script>
    import {getAbnormalList, setYjAbnormal} from "../api/abnormal";

    export default {
        name: "GiveMarkAnomaly",
        props: {
            queryObjFu: [Object],
            dialogVisibleFu: [Boolean]
        },
        data() {
            return {
                title: '作品编号：',
                dialogVisible: this.dialogVisibleFu,
                query: {
                    start: 1,
                    size: 1,
                },
                total: 0,
                dataList: [],
                loading: false,
                initialIndex: 0,
            }
        },
        methods: {
            onOpen() {
                let query = this.queryObjFu;
                let queryKey = this.queryObjFu;
                if (queryKey && Object.keys(queryKey).length > 0) {
                    this.query.start = Number(query.start);
                }
                this.getList();
            },
            onClose(type = false) {
                this.title = "作品编号：";
                this.dialogVisible = false;
                this.$emit('closeDialog', this.dialogVisible, type);
            },
            // 取消异常
            setNormal(id_card, type) {
                if (this.loading) {
                    return;
                }
                this.loading = true;
                this.$confirm('确认取消异常?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let obj = {
                        message: '',
                        id_card: id_card,
                        status: type
                    }
                    setYjAbnormal(obj)
                        .then(res => {
                            console.log(res);
                            if (res.data.code != 200) {
                                this.loading = false;
                                return this.$message.error(res.data.message);
                            }
                            this.$store.dispatch('GenerateRoutes').then(response => {
                            }).catch(error => {
                            })
                            this.loading = false;
                            this.onClose(true);
                            this.$message.success(res.data.message);
                        })
                        .catch(error => {
                            this.loading = false;
                            console.log(error);
                        });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消操作'
                    });
                    this.loading = false;
                });
            },
            getList() {
                if (this.loading) {
                    return;
                }
                this.loading = true;
                getAbnormalList(this.query)
                    .then(res => {
                        if (res.data.code != 200) {
                            this.dataList = [];
                            this.total = 0;
                            this.loading = false;
                            return this.$message.error(res.data.message);
                        }
                        this.loading = false;
                        this.dataList = res.data.data.list;
                        this.total = res.data.data.count;
                        this.title = '作品编号：' + this.dataList[0]['number'];
                    })
                    .catch(err => {
                        this.loading = false;
                        console.log(err)
                    })
            },
        },
        watch: {
            dialogVisibleFu() {
                this.dialogVisible = this.dialogVisibleFu;
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../assets/css/giveMark";
    .box_1{
        ::v-deep .el-dialog{
            margin-top: 15vh;
            width: 75vw;
            height: 75vh;
            min-width: 1000px;
            min-height: 600px;
            display: flex;
            flex-flow: column;

            .el-dialog__body {
                flex: 1;

                .item,
                .el-carousel,
                .el-carousel__container,
                .giveMark {
                    height: 100%;
                }

            }
        }
    }
</style>

