import {request} from "./network";
import store from "../store";

// 获取异常学生数据
export function getAbnormalList(data) {
    data['exam_uuid'] = store.state.examInfo.exam_uuid;
    data['subject_uuid'] = store.state.subject_uuid;
    data['assess_num'] = store.state.assess_num;
    return request({
        method: "post",
        url: "/yj/action/getAbnormalList",
        data,
    });
}

// 标记异常 (1-标为异常 2-取消异常)
export function setYjAbnormal(data) {
    data['exam_uuid'] = store.state.examInfo.exam_uuid;
    data['subject_uuid'] = store.state.subject_uuid;
    data['assess_num'] = store.state.assess_num;
    return request({
        method: "post",
        url: "/yj/action/setYjAbnormal",
        data,
    });
}

// 重置分数（分数归0）
export function delYjScore(data) {
    data['exam_uuid'] = store.state.examInfo.exam_uuid;
    data['subject_uuid'] = store.state.subject_uuid;
    data['assess_num'] = store.state.assess_num;
    return request({
        method: "post",
        url: "/yj/action/delYjScore",
        data,
    });
}
